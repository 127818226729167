import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import {
	NOT_FOUND_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { PageLoadEnd } from '@confluence/browser-metrics';
import { withRoutesContext } from '@confluence/route-manager';
import { HOME } from '@confluence/named-routes';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

import notFoundImage from './NotFoundIcon.svg';
import { ErrorPageComponent } from './ErrorPageComponent';
import { NOT_FOUND_PAGE_LOAD } from './perf.config';

const CUSTOM_ATTRIBUTES = {
	inaccessiblePageType: 'NOT_FOUND',
};

export const NotFound = withRoutesContext(({ push, toUrl, secondaryButtonProps }) => (
	<ErrorBoundary attribution={Attribution.BACKBONE}>
		<ExperienceStart name={NOT_FOUND_EXPERIENCE} />
		<div data-testid="confluence-not-found">
			<ErrorPageComponent
				errorImage={notFoundImage}
				errorTitle={
					<FormattedMessage
						id="not-found.header"
						// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
						// eslint-disable-next-line no-restricted-syntax
						defaultMessage="We couldn't find what you're looking for"
						description="Header line of a 404 Not Found page for any content type"
					/>
				}
				errorMessage={
					<FormattedMessage
						id="not-found.description"
						// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
						// eslint-disable-next-line no-restricted-syntax
						defaultMessage="We looked everywhere, but it doesn't exist. It may have been deleted. Unless, of course, the URL has a typo in it 😉"
						description="Line below the 404 Not Found header line"
					/>
				}
				primaryButtonProps={{
					children: (
						<FormattedMessage
							id="not-found.link.to.homepage"
							defaultMessage="Go home"
							description="A button that links to website's homepage"
						/>
					),
					onClick: () => push(toUrl(HOME.name)),
				}}
				secondaryButtonProps={secondaryButtonProps}
			/>
			<PageLoadEnd metric={NOT_FOUND_PAGE_LOAD} customData={CUSTOM_ATTRIBUTES} />
			<ExperienceSuccess name={NOT_FOUND_EXPERIENCE} />
		</div>
	</ErrorBoundary>
));

NotFound.displayName = 'NotFound';
