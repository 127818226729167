import React from 'react';
//todo use compiled when @atlaskit/image supports it
//the image is moved to a separate file due to
//https://atlassian.slack.com/archives/CFJ9DU39U/p1691750071893489
import css from '@emotion/css';

import Image from '@atlaskit/image';

const imageStyles = (isMobile) => css`
	width: ${isMobile ? '68%' : 'unset'};
	max-width: unset !important;
	margin: 0 auto;
`;

type ImageProps = {
	isMobile: boolean;
	src: string;
	srcDark?: string;
};

export function ErrorPageImage({ src, srcDark, isMobile }: ImageProps) {
	return <Image alt="" src={src} srcDark={srcDark || src} css={imageStyles(isMobile)} />;
}
